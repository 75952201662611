import { render, staticRenderFns } from "./StaffList.vue?vue&type=template&id=fbfd5c30&scoped=true&"
import script from "./StaffList.vue?vue&type=script&setup=true&lang=js&"
export * from "./StaffList.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./StaffList.vue?vue&type=style&index=0&id=fbfd5c30&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbfd5c30",
  null
  
)

export default component.exports